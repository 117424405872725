import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "optimising-angular-application"
    }}>{`Optimising Angular application`}</h1>
    <h5 {...{
      "id": "february-21-2020--aptitude-softwares-office"
    }}>{`February 21, 2020 | `}<a parentName="h5" {...{
        "href": "http://twitter.com/AptitudeSW"
      }}>{`Aptitude Software's`}</a>{` office`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://slides.com/kajetansw/optimizing-angular-apps"
        }}>{`https://slides.com/kajetansw/optimizing-angular-apps`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~40 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: presentation`}</li>
    </ul>
    <hr></hr>
    <p>{`Talk I gave as a part of Community of Practice (CoP) meetup at my company.`}</p>
    <p>{`I won't hide the fact - I was extremely inspired by a `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=ybNj-id0kjY"
      }}>{`talk`}</a>{` given by `}<a parentName="p" {...{
        "href": "https://twitter.com/mgechev"
      }}>{`Minko Gechev`}</a>{` on optimizing Angular applications during ngConf. It was one of the best Angular-related talks of 2019 - no doubts. `}</p>
    <p>{`I even noticed a trend that started developing in the community (I saw it mostly on Twitter) about this topic. Almost every week there was a new article/video/tutorial about it!`}</p>
    <p>{`So... Being totally influenced by the crowd I decided to give my own take on the topic! 😏 Also, I was happy that we've already completed by "intro to JS and Angular" workshop series - me and my colleagues from the office were more than ready to talk about more... "mature" stuff 😊`}</p>
    <hr></hr>
    <h3 {...{
      "id": "outline"
    }}>{`Outline`}</h3>
    <ul>
      <li parentName="ul">{`General tips - npm and package management`}</li>
      <li parentName="ul">{`General tips - JavaScript language`}</li>
      <li parentName="ul">{`Tips about TypeScript, RxJS and Angular`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      